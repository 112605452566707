window.LX_REQUIRE_MODULES = require("./modules");
window.APP_VERSION = {
    semantic: $$SEM_VERS$$,
    build: $$BUILD_DATE$$
};
// We just need to import this one, everything in it is set on the window object
require("platform");
window.Licences = [];
// region DEPRECATED

window.Styles = {...require("GlobalStyles").default};
// endregion
window.CCEvent = {
    StartMSSession: "StartSession",
    // miniserverObj
    ConnEstablished: "ConnEstablished",
    // miniserverObj, reachmode
    ConnClosed: "ConnClosed",
    StopMSSession: "StopSession",
    StructureReady: "StructureReady",
    STRUCTURE_UPDATE_FINISHED: "StructureUpdateFinished",
    // finished updating controls
    BatteryStateChanged: "BatteryStateChanged",
    // batteryInfoObj
    Pause: "Pause", // when the app is sent to the background (e.g. on android/mac)
    Resume: "Resume", // when the app is moved to the foreground again (e.g. on android/mac)
    Resign: "Resign",
    Active: "Active",
    NetworkChanged: "NetworkChanged",
    // networkInfoObj
    DownloadingLargeData: "DownloadingLargeData",
    // progress object
    PlatformCompReady: "PlatformComponentReady",
    AppInitInfoReady: "AppInitInfoReady",
    StateContainersCreated: "StateContainersCreated",
    CrucialDataLoaded: "CrucialDataLoaded",
    // all crucial information has been loaded, ready to download additional data
    // TaskRecorder
    TaskRecorderStart: "TaskRecorderStart",
    TaskRecorderEnd: "TaskRecorderEnd",
    // Notifications
    NotificationReceived: "NotificationReceived",
    // notification
    UnreadNotificationCount: "NotificationCount",
    // unreadCount
    ForceStateUpdate: "ForceStateUpdate",
    // changed from Dark- to Light-Mode or when the task recording ended
    STRUCTURE_UPDATE: "StructureUpdate",
    // update packet
    ALL_STATES_RECEIVED: "AllStatesReceived",
    // dispatched "A LOT"
    INITIAL_NETWORK_LOAD_PASSED: "InitialNetworkLoadPassed",
    // Initial network load is over (loxApp3.json has been loaded, and all states have been received, or max. 10 seconds after the loxApp3.json has been received)
    REMOVE_PRELOAD_LOGIN: "RemovePreloadLogin",
    // Removes the preload login screen
    REACHABILITY_UPDATE: "ReachabiltyUpdate",
    // Is triggered when the reachability changes, will carry the reach mode as a parameter
    MUSIC_ZONE_CHANGED: "MusicZoneChanged",
    EcoScreenDarkenerActive: "EcoScreenDarkenerActive",
    ResetAmbientToDefaultLocation: "ResetAmbientToDefaultLocation",

    DeviceActivityChanged: "DeviceActivityChanged", // Used to inform on when activity has been detected or not (on the device)

    AmbientModeSettingChanged: "AmbientModeSettingChanged", // new ambient mode setting modified
    EcoScreenSettingChanged: "2023EcoScreenSettingChanged", // new eco screen setting modified
    EntryPointSettingChanged: "EntryPointSettingChanged",
    AmbientTabEvent: "AmbientTabEvent", // used to identify which tab is/should be active in the hidden ambient tab navigator.

    PairedAppInfoReady: "PairedAppInfoReady",
    PairedAppPropertiesChanged: "PairedAppPropertiesChanged",
    UnpairedApp: "UnpairedApp",
    PairingResumeTimer: "PairingResumeTimer", // event fired if a timer starts/stops that should resume to the paired ms.
    PairedIdentify: "PairedIdentify", // event fired when paired identify is started/stopped
    Unauthorized: "unauthorzied" // dispatched if the ConnectionManager-Ext discovers an unauhtorized when establishing the websocket.
};

$.getJSON("resources/licenses.json", licenses => window.Licences = licenses);
window.ResourceImageCache = $$RESOURCE_CACHE$$;
window.UPDATE_LEVEL = $$UPDATE_LEVEL$$
window.BuildInfo = $$BUILD_INFO$$;
window.workerAvailable = typeof Worker !== "undefined";
window.NavigationComp = undefined;
window.MediaServerComp = undefined;
window.MusicServerComp = undefined;
window.UpdateComp = undefined;
window.hasCanvasError = false;
window.slowDevice = false;
window.VendorHub = angular.module("VendorHub", []);
let UtilityComp = require("./UtilityComp/utilities/helper.js");
Object.keys(UtilityComp).forEach(utilityKey => {
    if (utilityKey in window) {
        console.error(`${utilityKey} already on window!`);
    } else {
        window[utilityKey] = UtilityComp[utilityKey];
    }
});

let UrlHandlerExt = require("./NavigationComp/UrlHandlerExt");
window.handleOpenURL = UrlHandlerExt.handleOpenURL;
window.UrlHelper = UrlHandlerExt.UrlHelper;
