window.HD_APP = hasHDSize();

window.platformInfoObject = function platformInfoObject() {
  return {
    model: navigator.platform,
    manufacturer: "Loxone Webinterface",
    platform: PlatformType.Webinterface,
    uuid: window.deviceUuid,
    //btoa(PlatformType.Webinterface), // simply produce some gibberish
    version: navigator.appVersion,
    isTablet: window.HD_APP,
    has3DTouch: false,
    accessibility: {
      textSizeAdjustment: 100
    }
  };
};

window.appInfoObject = function appInfoObject() {
  return {
    appVersion: `${APP_VERSION.semantic} (${APP_VERSION.build})`,
    appName: 'Loxone'
  };
}; // adds same behaviour as Apps has (focus browser tab = start app, blur tab = app in background)
// $(window).blur(function() {
//     triggerEvent(document, "resign");
//     triggerEvent(document, "pause");
// });
// $(window).focus(function() {
//     triggerEvent(document, "resume");
//     triggerEvent(document, "active");
// });
